import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactComponent as EditRowIcon } from "../../../assets/images/icons/editRowIcon.svg";
import { ReactComponent as DeleteRowIcon } from "../../../assets/images/icons/deleteRowIcon.svg";
import EditCalenderHoliday from './EditCalenderHoliday';
import MonthDelModal from './MonthDelModal';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { toast } from 'react-toastify';

const EditCalender = () => {
    const token = localStorage.getItem("access_token");
    const [calenderData, setCalenderData] = useState([]);
    const [holidayEditModal, setHolidayEditModal] = useState(false);
    const [holiday, setHoliday] = useState();
    const [type, setType] = useState();
    const [monthDeleteModal, setMonthDeleteModal] = useState(false);
    const [month, setMonth] = useState();
    const [monthIdOrder, setMonthIdOrder] = useState();
    const [sessionId, setSessionId] = useState();
    useEffect(() => {
        getCalenderData();
    }, []);
    const DragHandle = sortableHandle(() => <div class="Showcase__style__handle"></div>);
    const getCalenderData = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + "/admin/getcalendar",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const objArr = Object.entries(response?.data);
            objArr?.forEach((single) => {
                single.showSubRow = false;
                if (single?.[1]) {
                    Object.values(single?.[1]).forEach(value => {
                        value.push({
                            calendarId
                                :
                                0,
                            date
                                :
                                "2025-04-18|2025-04-20",
                            id
                                :
                                [],
                            monthId
                                :
                                0,
                            season
                                :
                                "",
                            session_id
                                :
                                0,
                            text
                                :
                                "",
                            show: false
                        })
                    })
                }
            })
         
            setCalenderData(objArr);
        } catch (error) {
            console.error("Error fetching calendar data:", error);
        }
    };

    function formatDateRange(dateString) {
        const dates = dateString?.split('|');
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);

        const startDay = startDate.getDate();
        const endDay = endDate.getDate();
        const startMonth = startDate.toLocaleString('default', { month: 'long' });
        const endMonth = endDate.toLocaleString('default', { month: 'long' });

        const formattedStartDate = `${startDay}${getOrdinalSuffix(startDay)} ${startMonth}`;
        const formattedEndDate = endDay ? `${endDay}${getOrdinalSuffix(endDay)} ${endMonth}` : '';

        return `${formattedStartDate}${formattedEndDate ? ` - ${formattedEndDate}` : ''}`;
    }

    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }

    const EditHolidayHandler = (holiday) => {
        setType("Edit");
        setHoliday(holiday);
        setHolidayEditModal(true);
    };

    const deleteHolidayHandler = (holiday) => {
        setType("Delete");
        setHoliday(holiday);
        setHolidayEditModal(true);
    };

    const deleteMonthHandler = (holiday, month) => {
        setHoliday(holiday);
        setMonth(month);
        setMonthDeleteModal(true);
    };

    const SortableItem = SortableElement(({ holidays, month, index }) => (
        <>
            <tr style={{ backgroundColor: "rgba(134, 134, 134, 0.1490196078)", marginBottom: "20px" }}>
                <td> <DragHandle /></td>
                <td>{holidays?.[0]?.monthId}</td>
                <td>{month}</td>
                <td style={{ display: "flex" }}>
                    <button onClick={() => deleteMonthHandler(holidays, month)}>
                        <DeleteRowIcon />
                    </button>
                    <button className="btn btn-link adminbtn adminbtn-primary"
                        style={{ margin: "10px" }}
                        data-toggle="collapse"
                        data-target={`#demo10`}
                        onClick={() => {
                            const { monthId } = holidays[0]
                            const temp = calenderData;
                            calenderData?.forEach((single) => {
                                Object.values(single?.[1]).forEach(value => {
                                    if (value[0]?.monthId === monthId) {
                                        value[value?.length - 1].show = !value[value?.length - 1].show
                                    }
                                })
                            })
                        }}>{month?.slice(0, 3)} Holidays

                    </button>
                </td>
            </tr>
            {holidays[holidays?.length - 1]?.show && <tr>


                <table className="table table-striped" style={{ margin: "40px" }}>
                    <thead>
                        <tr>
                            <th style={{ color: "white" }}>Date</th>
                            <th style={{ color: "white" }}>Holiday Name</th>
                            <th style={{ color: "white" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {holidays.map((holiday, j) => (
                            j !== holidays?.length - 1 && <tr key={j}>
                                <td>{formatDateRange(holiday.date)}</td>
                                <td>{holiday.text}</td>
                                <td>
                                    <button onClick={() => EditHolidayHandler(holiday)}>
                                        <EditRowIcon />
                                    </button>
                                    &nbsp;
                                    <button onClick={() => deleteHolidayHandler(holiday)}>
                                        <DeleteRowIcon />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


            </tr>}
        </>
    ));

    const SortableList = SortableContainer(({ items }) => {
        return (
            <tbody>
                {items.map(([month, holidays], i) => (
                    <>

                        <SortableItem
                            key={`item-${i}`}
                            index={i}
                            holidays={holidays}
                            month={month}
                        />



                    </>

                ))}
            </tbody>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }, index) => {
        setCalenderData((prevCalenderData) => {
            const updatedData = [...prevCalenderData];
            const monthEntries = Object.entries(updatedData[index][1]);
            const newMonthEntries = arrayMoveImmutable(monthEntries, oldIndex, newIndex);
            updatedData[index][1] = Object.fromEntries(newMonthEntries);
            let monthIdsArr = [];
          
            // setSessionId()
            Object?.values(updatedData[index][1])?.map((item) =>
            {
                monthIdsArr?.push(item?.[0]?.monthId)
                setSessionId(item?.[0]?.session_id)
            }
            
            )
            setMonthIdOrder(monthIdsArr);
            return updatedData;
        });
    };

    const monthOrderHandler = async() => {
    
        const data={
            monthIds:monthIdOrder
        };
        try {
            const response= await axios.put(
                process.env.REACT_APP_BASE_URL + `/admin/update-month-order/${sessionId}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
          
            if(response?.status===200 || response?.status===201){
                toast.success("Order updated Successfully.")
            }
        } catch (error) {
            console.error("Error while ordering the Months");
            toast.error("Error while ordering the Months");
        }
    }
    return (
        <>
            {holidayEditModal && (
                <EditCalenderHoliday
                    setHolidayEditModal={setHolidayEditModal}
                    holiday={holiday}
                    getCalenderData={getCalenderData}
                    type={type}
                />
            )}

            {monthDeleteModal && (
                <MonthDelModal
                    setMonthDeleteModal={setMonthDeleteModal}
                    holiday={holiday}
                    month={month}
                    getCalenderData={getCalenderData}
                />
            )}

            <div className="container">
                <div className="col-md-12">
                    <div className="panel panel-default">
                        <div className="panel-heading">Calendar</div>
                        <div className="panel-body">
                           
                            {calenderData?.map((item, index) => (
                                <div>
                                    <table className="table table-condensed table-striped" key={index}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th style={{ color: "white" }}>Season</th>
                                                <th style={{ color: "white" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                data-toggle="collapse"
                                                data-target={`#demo${index}`}
                                                className="accordion-toggle"
                                            >
                                                <td>
                                                    <button className="btn btn-default btn-xs">
                                                        <span className="glyphicon glyphicon-eye-open"></span>
                                                    </button>
                                                </td>
                                                <td>Fall {item?.[0]}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="12" className="hiddenRow">
                                                    <div
                                                        className="accordian-body collapse"
                                                        id={`demo${index}`}
                                                    >
                                                        <table>
                                                            <thead>
                                                                <tr className="info">
                                                                    <th></th>
                                                                    <th style={{ color: "white" }}>Month Id</th>
                                                                    <th style={{ color: "white" }}>Month</th>
                                                                    <th style={{ color: "white" }}>Action</th>
                                                                </tr>
                                                            </thead>

                                                            <SortableList
                                                                items={Object.entries(item?.[1])}
                                                                onSortEnd={(sortEvent) => onSortEnd(sortEvent, index)}
                                                                useDragHandle={true}
                                                            />


                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button
                                        className='adminbtn adminbtn-primary'
                                        style={{ margin: "10px" }}
                                        onClick={() => monthOrderHandler()}
                                        disabled={sessionId ? false : true}
                                    >Save Order</button>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCalender;
