import { createSlice } from "@reduxjs/toolkit";

export const categorySumSlice=createSlice({
    name:"categorySumSlice",
    initialState:{
        categorySumCompleteData:[],
        dateCopy:'',
        tabelHeadersData:[],
        selectedReport:"Category Summary"
    },
    reducers:{
        setCategorySumCompleteData:(state,action)=>{
            state.categorySumCompleteData=action.payload.categorySumCompleteData;
            state.dateCopy=action.payload.dateCopy;
            state.selectedReport="Category Summary";
            state.tabelHeadersData=action.payload.tabelHeadersData
        },
    },
});
export const {setCategorySumCompleteData}=categorySumSlice.actions;

export default categorySumSlice?.reducer;