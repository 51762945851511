import React, { useEffect, useState } from "react";
import bannerImage from "../../parent-assets/images/home-banner.jpg";
import "./Calender.css";
import axios from "axios";
import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
import AboutUsShimmer from "../../../Admin/components/ShimmerUi/AboutUsShimmer";
function Calender() {
  const token = localStorage.getItem("access_token");
  const [calenderData, setCalenderData] = useState([]);
  const [headerData, setHeaderData] = useState();
  const [showShimmer, setShowShimmer] = useState(false);
  useEffect(() => {
    getCalenderData();
    getHeaderImage();
  }, []);

  const getHeaderImage = async () => {
    try {
      setShowShimmer(true);
      const response = await getSectionCMS(token, 180);
      setHeaderData(response);
      setShowShimmer(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getCalenderData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/admin/getcalendar",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const objArr = Object.entries(response?.data);
      setCalenderData(objArr);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  function formatDateRange(dateString) {
    // Split the date string by "|"
    const dates = dateString.split("|");

    // Function to get ordinal suffix for a number
    function getOrdinalSuffix(number) {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = number % 100;
      return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
    }

    // Function to format day with ordinal suffix
    function formatDay(day) {
      const dayNumber = parseInt(day.split("-")[2], 10); // Extract day part
      const suffix = getOrdinalSuffix(dayNumber); // Get ordinal suffix
      return `${dayNumber}${suffix}`;
    }

    // Format each day and join with "-"
    const formattedDates = dates.map((date) => formatDay(date)).join("-");

    return formattedDates;
  }
  function getWeekdaysFromDateRange(dateString) {
    // Split the date string by "|"
    const dates = dateString.split("|");

    // Function to get weekday for a given date
    function getWeekday(dateString) {
      const [year, month, day] = dateString.split("-");
      const date = new Date(year, parseInt(month, 10) - 1, day);
      return date.toLocaleDateString("en-US", { weekday: "short" });
    }

    // Get weekdays for each date
    const weekdays = dates.map((date) => getWeekday(date));

    return `${weekdays[0]}${weekdays[1] ? `-${weekdays[1]}` : ""}`;
  }
  const getMonthsHandler = (value) => {
    const keyValue = Object.entries(value);
    return (
      <div className="holiday_list_container">
        {keyValue?.map((item, index) => (
          <div className="month_wrapper" key={index}>
            <div className="month_holiday_calender">
              <h1
                className="calender_month_name"
                style={{
                  color:
                    index == 0
                      ? "#c3d940"
                      : index == 1
                      ? "#00bbe3"
                      : index == 2
                      ? "#f79727"
                      : index == 3
                      ? "#c3d940"
                      : index == 4
                      ? "#00bbe3"
                      : index == 5
                      ? "#f79727"
                      : index == 6
                      ? "#ea519c"
                      : index == 7
                      ? "#00bbe3"
                      : index == 8
                      ? "#f79727"
                      : index == 9
                      ? "#593f98"
                      : index == 10
                      ? "#c3d940"
                      : "#ea519c",
                }}
              >
                {item?.[0]}
              </h1>
              <div className="month_holiday_container">
                {item?.[1]?.map((items, idx) => (
                  <div className="holiday_container" key={idx}>
                    <div className="date_container">
                      {formatDateRange(items?.date)}
                    </div>
                    <div className="holiday_name">
                      {getWeekdaysFromDateRange(items?.date)}
                    </div>
                    <div className="holiday_name">{items?.text}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="borderBotton-line"></div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {showShimmer ? (
        <AboutUsShimmer />
      ) : (
        <div className="contentwraper">
          <section style={{ position: "relative" }}>
            <section className="siteBanner" style={{ background: "#C3D940" }}>
              <div className="siteBanner__overlay">
                <img
                  className="siteBanner__img"
                  src={
                    headerData?.[0]?.imageSection?.image
                      ? headerData?.[0]?.imageSection?.image
                      : bannerImage
                  }
                  alt="calender banner img"
                />
              </div>
            </section>
            <div className="navbar_heading_siteBanner_img">
              {/* <h1 className="navbar_heading_siteBanner_img_heading"> Calender</h1> */}
            </div>
          </section>
          <section className="commonSec">
            <div className="container calender_container">
              {calenderData?.map((season, index) => (
                <div
                  className="calenderBox calender_container"
                  key={season?.[0]}
                >
                  <h1 className="calender_heading">
                    Fall Session ({season?.[0]})
                  </h1>
                  {/* <h5>Fall Session begins Dec 12th-Aug 8th 2024</h5> */}
                  <div className="filterBox"></div>
                  {/* <div className="holiday_list_container"> */}

                  {getMonthsHandler(season?.[1])}
                  {/* </div> */}
                  <h6 className="holiday_calender_desclaimer">
                    All Dates are subject to change
                  </h6>
                  <hr />
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Calender;

// =====================================Static Calender==============================

// import React, { useEffect, useState } from "react";
// import bannerImage from "../../parent-assets/images/about-us-banner.jpg";
// import { dummyData, dummyData2024 } from "./Contact";
// import "./Calender.css";
// import axios from "axios";
// import { getSectionCMS } from "../../../Admin/adminApi/mastermenu";
// function Calender() {
//   const token = localStorage.getItem("access_token");
//   const [calenderData, setCalenderData] = useState([]);
//   const [headerData, setHeaderData] = useState();
//   useEffect(() => {
//     getCalenderData();
//     getHeaderImage();
//   }, [])
//   const getHeaderImage = async () => {
//     try {
//       const response = await getSectionCMS(token, 180);
//       setHeaderData(response)
//     } catch (error) {
//       console.error(error);
//     }
//   }
//   const getCalenderData = async () => {
//     try {
//       const response = await axios.get(
//         process.env.REACT_APP_BASE_URL + '/admin/getcalendar',
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           }
//         }
//       );

//       setCalenderData(response?.data?.data)
//     } catch (error) {
//       console.error("Error fetching calendar data:", error);
//     }
//   }

//   return (
//     <>
//       <div className="contentwraper">
//         <section style={{ position: "relative" }}>
//           <section className="siteBanner" style={{ background: "#C3D940" }}>
//             <div className="siteBanner__overlay">
//               <img
//                 className="siteBanner__img"
//                 src={headerData?.[0]?.imageSection?.image ?
//                   headerData?.[0]?.imageSection?.image
//                   : bannerImage}
//                 alt="calender banner img"
//               />
//             </div>
//           </section>
//           <div className="navbar_heading_siteBanner_img">
//             {/* <h1 className="navbar_heading_siteBanner_img_heading"> Calender</h1> */}
//           </div>
//         </section>
//         <section className="commonSec">
//           <div className="container">
//             <div className="calenderBox">
//               <h1 className="calender_heading">Fall Session (2023-24)</h1>
//               <h5>Fall Session begins Dec 12th-Aug 8th 2024</h5>
//               <div className="filterBox"></div>
//               <div className="holiday_list_container">
//                 {dummyData?.map((month, index) => (
//                   <div key={index} className="month_holiday_container">
//                     <div className="month_name_container">
//                       {/* <span class="material-symbols-outlined calender_icon"
//                         style={{
//                           color: index == 0 ? "#c3d940" :
//                             index == 1 ? "#00bbe3" :
//                               index == 2 ? "#f79727" :
//                                 index == 3 ? "#c3d940" :
//                                   index == 4 ? "#00bbe3" :
//                                     index == 5 ? "#f79727" :
//                                       index == 6 ? "#ea519c" :
//                                         index == 7 ? "#00bbe3" :
//                                           index == 8 ? "#f79727" :
//                                             index == 9 ? "#593f98" :
//                                               index == 10 ? "#00bbe3" :
//                                                 "#223d98"

//                         }}
//                       >
//                         event_note
//                       </span> */}
//                       <h3 className="month_name"
//                         style={{
//                           color: index == 0 ? "#c3d940" :
//                             index == 1 ? "#00bbe3" :
//                               index == 2 ? "#f79727" :
//                                 index == 3 ? "#c3d940" :
//                                   index == 4 ? "#00bbe3" :
//                                     index == 5 ? "#f79727" :
//                                       index == 6 ? "#ea519c" :
//                                         index == 7 ? "#00bbe3" :
//                                           index == 8 ? "#f79727" :
//                                             index == 9 ? "#593f98" :
//                                               index == 10 ? "#00bbe3" :
//                                                 "#223d98"

//                         }}
//                       >
//                         {month?.month}
//                       </h3>
//                     </div>
//                     {month?.holidays?.map((holiday, holidayIndex) => (
//                       <div key={holidayIndex} className="holiday_entry">
//                         <span className="holiday_date">{holiday?.date}</span>
//                         <span className="holiday_day">{holiday?.day}</span>
//                         <span className="holiday_event">{holiday?.Event}</span>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </div>
//             </div>
//             <hr />
//             <div className="calenderBox">
//               <h1 className="calender_heading">Summer Session (2024-25)</h1>
//               <h5>Classes and Camp begin Tuesday Sept 3rd</h5>
//               <div className="filterBox"></div>
//               <div className="holiday_list_container">
//                 {dummyData2024?.map((month, index) => (
//                   <div key={index} className="month_holiday_container">
//                     <div className="month_name_container">
//                       {/* <span class="material-symbols-outlined calender_icon"

//                         style={{
//                           color: index == 0 ? "#c3d940" :
//                             index == 1 ? "#00bbe3" :
//                               index == 2 ? "#f79727" :
//                                 index == 3 ? "#c3d940" :
//                                   index == 4 ? "#00bbe3" :
//                                     index == 5 ? "#f79727" :
//                                       index == 6 ? "#ea519c" :
//                                         index == 7 ? "#00bbe3" :
//                                           index == 8 ? "#f79727" :
//                                             index == 9 ? "#593f98" :
//                                               index == 10 ? "#00bbe3" :
//                                                 "#223d98"

//                         }}
//                       >
//                         event_note
//                       </span> */}
//                       <h3 className="month_name"
//                         style={{
//                           color: index == 0 ? "#c3d940" :
//                             index == 1 ? "#00bbe3" :
//                               index == 2 ? "#f79727" :
//                                 index == 3 ? "#c3d940" :
//                                   index == 4 ? "#00bbe3" :
//                                     index == 5 ? "#f79727" :
//                                       index == 6 ? "#ea519c" :
//                                         index == 7 ? "#00bbe3" :
//                                           index == 8 ? "#f79727" :
//                                             index == 9 ? "#00bbe3" :
//                                               index == 10 ? "#00bbe3" :
//                                                 index == 11 ? "#c3d940" :
//                                                   "#ea519c"

//                         }}
//                       >
//                         {month?.month}
//                       </h3>
//                     </div>
//                     {month?.holidays?.map((holiday, holidayIndex) => (
//                       <div key={holidayIndex} className="holiday_entry">
//                         <span className="holiday_date">{holiday?.date}</span>
//                         <span className="holiday_day">{holiday?.day}</span>
//                         <span className="holiday_event">{holiday?.Event}</span>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//       <h6 className="holiday_calender_desclaimer">
//         All Dates are subject to change
//       </h6>
//     </>
//   );
// }

// export default Calender;
